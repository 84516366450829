<template>
  <div class="box">
    <el-form :model="form" ref="form" class="form">
      <div class="title ">考试基础信息</div>
      <el-form-item label="考试名称:">
        <div class="data">{{form.name}}</div>
      </el-form-item>
      <el-form-item label="考试类型:">
        {{form.type}}
      </el-form-item>
      <div class="title ">考试适用范围</div>
      <el-form-item label="适用校区:">
        <el-tag v-for="item in form.school_names" style="margin-right: 10rem;">
          {{ item }}
        </el-tag>
      </el-form-item>
      <el-form-item ref="grade" label="考试年级:">
        <el-tag v-for="item in form.grade_ids" style="margin-right: 10rem;">{{item}}</el-tag>
      </el-form-item>
      <el-form-item ref="classType" label="班级类型:">
        <el-tag v-for="item in form.class_type" style="margin-right: 10rem;">{{item}}</el-tag>
      </el-form-item>
      <el-form-item label="选择班级:">
        <div>
          <span v-for="(grade_class,index) in classSelected" :key="'class'+index">
            <el-tag v-for="(item,_index) in grade_class" :key="'_class'+index+_index" style="margin-right: 15rem;">
            {{item.class_name}}
          </el-tag>
          </span>
        </div>
      </el-form-item>
      <div class="title ">考试过程安排</div>

      <div class="subject" v-for="(item,index) in form.courses">
        <div class="item_box">
          <div class="item">
            <span>考试科目:</span>
            <el-form-item>
              {{item.subject_child}}
            </el-form-item>
          </div>
          <div class="item">
            <span>开始时间:</span>
            <el-form-item >
              {{item.start_at}}
            </el-form-item>
          </div>
          <div class="item">
            <span>结束时间:</span>
            <el-form-item>
              {{item.end_at}}
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div class="footer-button">
      <el-button type="primary" @click="$router.back();">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"show","meta":{"title":"查看"}}},
  data(){
    return {
      form:{
        name:null,
        type_id:null,
        school_ids:[],
        grade_ids:[],
        class_type:null,
        class_ids:[],
        courses:[{}],
      },
      //选择班级模态框
      classVisible:false,
      // 考试类型列表
      typeList:[],
      // 班级类型列表
      classTypeList:[],
      // 年级列表
      gradeList:[],
      // 班级列表
      classList:[],
      // 班级全选状态列表
      classSelectedAll:[],
      // 班级已选中列表
      classSelected:[],
      // 班级临时全选状态列表
      classSelectedAllTemp:[],
      // 班级临时已选中列表
      classSelectedTemp:[],
      // 科目列表
      subjectList:[],
      // 关闭dialog的状态
      dialogIsConfirm:false,
      // 数据id
      id:null
    }
  },
  watch:{
  },
  methods:{
    /**检查form表单中的一些数据是否为空*/
    checkForm(){
      let keys = ["type_id","class_type","school_ids","grade_ids"];
      for (let i of keys){
        if (!this.form[i])return false;
      }
      return true;
    },
    /**初始化*/
    init(){
      // 获取考试类型
      this.$_axios.get("/exam/type").then(res=>{
        let {data} = res.data;
        this.typeList = this.$tools.jsonToArray(data);
      })
      // 获取考试科目
      this.$_axios.get("/exam/subject").then(res=>{
        let {data} = res.data;
        this.subjectList = data;
      })
      // 获取考试初始数据
      this.$_axios.get("/exam/"+this.id).then(res=>{
        let {data} = res.data;
        data.school_ids = this.$tools.objectNumberToggle(data.school_ids,1)
        data.type_id += "";
        data.class_type += "";
        data.courses.forEach(item=>{
          item.subject_id = [item.subject_pid+"",item.subject_id+""]
          delete item.subject_pid;
        })
        this.form = data;
        this.getClassData();
        this.typeChange(data.type_id)

        // 获取年级列表
        this.$_axios.get("/exam/grade").then(res=>{
          let {data} = res.data;
          this.filterFormData('grade_ids',data)
        })

        // 获取班级类型
        this.$_axios.get("/exam/class-type").then(res=>{
          let {data} = res.data;
          this.filterFormData('class_type',data);
        })
      })
    },
    /**
     * @desc 筛选有用的展示数据
     * @param {string} name
     * @param {Object} data
     * */
    filterFormData(name,data){
      let temp = {};
      let form = this.form[name];
      if (typeof form === "object"){
        form.forEach(item=>{
          temp[item] = data[item]
        })
      }else{
        temp[form] = data[form]
      }

      this.form[name] = temp;
    },
    /**获取班级数据*/
    getClassData(){
      let flag = true;
      if (!flag)return;
      this.$_axios.post("/exam/classes",{
        type:this.form.class_type,
        school:this.form.school_ids,
        grade:this.form.grade_ids
      }).then(res=>{
        let {data} = res.data;
        if (this.classSelectedTemp.length===0)this.classSelectedTemp = data.map(_=>[]);
        if (this.classSelected.length===0)this.classSelected= data.map(_=>[]);
        if (this.classSelectedAllTemp.length===0)this.classSelectedAllTemp= data.map(_=>false);
        if (this.classSelectedAll.length===0)this.classSelectedAll = data.map(_=>false);
        this.classList = data;
        let class_ids = this.form.class_ids;
        for (let i in data){
          this.classSelectedTemp[i]  = this.classSelectedTemp[i]||[];
          let item = data[i];
          for (let j in item.data){
            let children = item.data[j];
            let id = children.id;
            if (class_ids.indexOf(id) !== -1){
              this.classSelectedTemp[i].push(children);
            }
          }
        }
        this.classSelected = this.classSelectedTemp;
      })
    },
    /**考试类型被更改*/
    typeChange(e){
      this.$_axios.get("/exam/school",{
        params:{
          id:e
        }
      }).then(res=>{
        let {data} = res.data;
        let temp = {};
        this.form.school_ids.forEach(item=>{
          temp[item] = data[item]
        })
       this.form.school_ids = temp;
      })
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.box{
  .form{
    font-size: 14rem;
    margin: 0 0 20rem 50rem;
    .title{
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }
    .el-form-item{
      margin-left: 50rem;
    }
    .subject{
      display: flex;
      align-items: center;
      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;
      .item_box{
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 12rem 10rem;
        flex: 1;
      }
      .item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;
        >span{
          margin-right: 15rem;
        }

        .btn{
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns{
        width: 100rem;
        justify-content: flex-start;
        text-align: left;
        .btn{
          display: inline-block;
        }
      }

      .el-form-item{
        margin: 0;
      }

    }
    @media only screen and (max-width: 1399rem){
      .subject{
        .item{
          display: block;
          text-align: center;
          >span{
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }
          ::v-deep .el-input{
            width: 180rem !important;
          }
        }
      }
    }
  }
}
</style>
